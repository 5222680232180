import { Component } from '@angular/core';

import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { UserService } from './user.service';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Autostart } from '@ionic-native/autostart/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { OrdersService } from './orders.service';
import { EventsService } from './events.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Location } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Geolocation } from '@ionic-native/geolocation/ngx';

// latest version testing
declare var google: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  public appPages = [
    {
      title: 'Bestellungen',
      url: '/orders',
      icon: 'receipt-outline',
      requiresLogin: true,
    },
    // {
    //   title: 'Daueraufträge',
    //   url: '/scheduled-orders',
    //   icon: 'repeat-outline',
    //   requiresLogin: true,
    // },
    /*
    {
      title: 'Gutscheine',
      url: '/coupons',
      icon: 'gift-outline',
      requiresLogin: true,
    },
    */
    {
      title: 'Liefergebiet',
      url: '/map',
      icon: 'pin-outline',
      requiresLogin: true,
    },
    /*
    {
      title: 'Öffnungszeiten',
      url: '/openings',
      icon: 'time-outline',
      requiresLogin: true,
    },
    {
      title: 'Produkte',
      url: '/products',
      icon: 'pricetags-outline',
      requiresLogin: true,
    },
    {
      title: 'Verfügbarkeit',
      url: '/availability',
      icon: 'checkmark',
      requiresLogin: true,
    },
    */
    {
      title: 'Einstellungen',
      url: '/settings',
      icon: 'cog',
      requiresLogin: true,
    },
  ];

  soundFile: string = 'assets/sound.mp3';

  user: any;
  geocoder: any;

  constructor(
    public alertCtrl: AlertController,
    public autoStart: Autostart,
    private backgroundMode: BackgroundMode,
    public events: EventsService,
    public nativeAudio: NativeAudio,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public translations: TranslateService,
    public userService: UserService,
    public oneSignal: OneSignal,
    public ordersService: OrdersService,
    private screenOrientation: ScreenOrientation,
    private _location: Location,
    public geolocation: Geolocation,
  ) {
    this.user = this.userService.getUser() || {};

    this.initializeApp();
    registerLocaleData(localeDe);

    this.events.subscribe('alert', async (alertConfig: any) => {
      let config: any = {
        header: alertConfig.header || 'Info',
        message: alertConfig.message || 'Ein unbekannter Fehler ist aufgetreten',
        buttons: alertConfig.buttons || [
          {
            text: 'Okay'
          }
        ]
      };
      if(alertConfig.subHeader) {
        config.subHeader = alertConfig.subHeader;
      }
      let alert = await this.alertCtrl.create(config);
      await alert.present();
    });

    this.events.subscribe('error', async (error: string) => {
      console.log('error', error);
      let blIsCurlError = error.toLowerCase().indexOf('curl') !== -1;
      console.log('blIsCurlError', blIsCurlError);
      if(blIsCurlError) {
        console.log('curl error, curl error handling');
        return false;
      }
      let alert = await this.alertCtrl.create({
        header: 'Fehler',
        message: error,
        buttons: [
          {
            text: 'Okay'
          }
        ]
      });
      await alert.present();
    });

    this.events.subscribe('user:loggedin', () => {
      this.user = this.userService.getUser() || {};
      this.initPush();
    });

    this.events.subscribe('user:loggedout', () => {
      this.user = this.userService.getUser() || {};
    });
    
  }

  async getLocation() {       
    this.geolocation.getCurrentPosition().then((resp) => {
      // resp.coords.latitude
      // resp.coords.longitude
     }).catch((error) => {
       console.log('Error getting location', error);
     });      
  }

  initInsomnia() {
    try {
      (window as any).plugins.insomnia.keepAwake();
    } catch(e) {
      console.warn('insomnia error', e);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#00000');
      this.statusBar.styleBlackTranslucent();
      this.statusBar.backgroundColorByName('white');
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.getLocation();

      this.translations.setDefaultLang('de');

      // this.backgroundMode.enable();
      // this.backgroundMode.moveToBackground();
      // this.backgroundMode.overrideBackButton();

      this.autoStart.enable();
      this.initInsomnia();

      if(this.platform.is('android')) {
        this.initKiosk();
        // this.statusBar.hide();
        // this.statusBar.overlaysWebView(true);
      }
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      // if (this.platform.is('tablet')) {
      //   if (this.screenOrientation.type === 'portrait-primary') {
      //     this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      //   }
      // }

      if(this.user && this.user.uid) {
        this.initPush();
        // this.ordersService.watch();
      }

      try {
        this.nativeAudio.preloadSimple('sound1', this.soundFile)
        .then((response: any) => {}, (e: any) => {
          console.warn('push (e 3)', e);
        });
      } catch(e) {
        console.warn('push (e 2)', e);
      }

      this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
        console.log('Back press handler!');
        if (this._location.isCurrentPathEqualTo('/login') || this._location.isCurrentPathEqualTo('/orders')) {
  
          // Show Exit Alert!
          console.log('Show Exit Alert!');
          this.showExitConfirm();
          processNextHandler();
        } else {
  
          // Navigate to back page
          console.log('Navigate to back page');
          this._location.back();
  
        }
  
      });
  
      this.platform.backButton.subscribeWithPriority(5, () => {
        console.log('Handler called to force close!');
        this.alertCtrl.getTop().then(r => {
          if (r) {
            navigator['app'].exitApp();
          }
        }).catch(e => {
          console.log(e);
        })
      });


    });
  }

  showExitConfirm() {
    this.alertCtrl.create({
      header: 'App-Beendigung',
      message: 'Möchten Sie die App schließen?',
      backdropDismiss: false,
      buttons: [{
        text: 'Bleibe',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Ausgang',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  initKiosk() {
    try {
      if((window as any).Kiosk) {
        (window as any).Kiosk.setKioskEnabled(true);
      }
    } catch(e) {
      console.warn('kiosk error', e);
    }
  }

  initPush() {
    this.oneSignal.startInit('b22ad144-15eb-4eb0-a475-420e5a1d7f02', '505924463265');
    if(this.platform.width() > this.platform.height()) {
      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
      this.oneSignal.sendTag('type', 'store');
    } else {
      this.oneSignal.sendTag('type', 'driver');
    }
    this.oneSignal.endInit();
  }

}