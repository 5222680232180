import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "pages/:pageId",
    loadChildren: "./dynamic/dynamic.module#DynamicPageModule",
  },
  {
    path: "order/:orderId",
    loadChildren: "./order/order.module#OrderPageModule",
  },
  {
    path: "time-tracking",
    loadChildren: () =>
      import("./time-tracking/time-tracking.module").then(
        (m) => m.TimeTrackingPageModule
      ),
  },
  {
    path: "create-catalog",
    loadChildren: () =>
      import("./create-catalog/create-catalog.module").then(
        (m) => m.CreateCatalogPageModule
      ),
  },
  {
    path: "create-catalog-more",
    loadChildren: () =>
      import("./create-catalog-more/create-catalog-more.module").then(
        (m) => m.CreateCatalogMorePageModule
      ),
  },
  {
    path: "catalog-statistics",
    loadChildren: () =>
      import("./catalog-statistics/catalog-statistics.module").then(
        (m) => m.CatalogStatisticsPageModule
      ),
  },
  {
    path: "availability",
    loadChildren: () =>
      import("./availability/availability.module").then(
        (m) => m.AvailabilityPageModule
      ),
  },
  {
    path: "coupons",
    loadChildren: () =>
      import("./coupons/coupons.module").then((m) => m.CouponsPageModule),
  },
  {
    path: "create-task",
    loadChildren: () =>
      import("./create-task/create-task.module").then(
        (m) => m.CreateTaskPageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "logout",
    loadChildren: () =>
      import("./logout/logout.module").then((m) => m.LogoutPageModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardPageModule),
  },
  {
    path: "openings",
    loadChildren: () =>
      import("./openings/openings.module").then((m) => m.OpeningsPageModule),
  },
  {
    path: "create-product",
    loadChildren: () =>
      import("./create-product/create-product.module").then(
        (m) => m.CreateProductPageModule
      ),
  },
  {
    path: "store",
    loadChildren: () =>
      import("./store/store.module").then((m) => m.StorePageModule),
  },
  {
    path: "deliveryrange",
    loadChildren: () =>
      import("./deliveryrange/deliveryrange.module").then(
        (m) => m.DeliveryrangePageModule
      ),
  },
  {
    path: "product",
    loadChildren: () =>
      import("./product/product.module").then((m) => m.ProductPageModule),
  },
  {
    path: "products",
    loadChildren: () =>
      import("./products/products.module").then((m) => m.ProductsPageModule),
  },
  {
    path: "map",
    loadChildren: () => import("./map/map.module").then((m) => m.MapPageModule),
  },
  {
    path: "orders",
    loadChildren: () =>
      import("./orders/orders.module").then((m) => m.OrdersPageModule),
  },
  {
    path: "projects",
    loadChildren: () =>
      import("./projects/projects.module").then((m) => m.ProjectsPageModule),
  },
  {
    path: "task",
    loadChildren: () =>
      import("./task/task.module").then((m) => m.TaskPageModule),
  },
  {
    path: "tasks",
    loadChildren: () =>
      import("./tasks/tasks.module").then((m) => m.TasksPageModule),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/settings.module").then((m) => m.SettingsPageModule),
  },
  {
    path: "scheduled-orders",
    loadChildren: () =>
      import("./scheduled-orders/scheduled-orders.module").then(
        (m) => m.ScheduledOrdersPageModule
      ),
  },
  {
    path: 'message',
    loadChildren: () => import('./message/message.module').then( m => m.MessagePageModule)
  },
  {
    path: 'appearance',
    loadChildren: () => import('./appearance/appearance.module').then( m => m.AppearancePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
