import { Injectable } from '@angular/core';
import { Printer } from '@ionic-native/printer/ngx';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';

import * as moment from 'moment';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  bluetoothList: any;
  productList:any;

  constructor(
    public alertCtrl: AlertController,
    public btSerial: BluetoothSerial,
    public printer: Printer,
  ) { }

  searchBluetoothPrinter() {
    //This will return a list of bluetooth devices
    return this.btSerial.list();
  }
  connectToBluetoothPrinter(macAddress) {
    //This will connect to bluetooth printer via the mac address provided
    return this.btSerial.connect(macAddress)
  }

  disconnectBluetoothPrinter() {
    return this.btSerial.disconnect();
  }
  
  sendToBluetoothPrinter(macAddress, data_string) {
    return new Promise((resolve, reject) => {
      this.connectToBluetoothPrinter(macAddress)
      .subscribe(_ => {
        this.btSerial.write(data_string)
          .then(_ => {
            this.disconnectBluetoothPrinter().then(resolve).catch(reject);
          }, reject)
          .catch(reject);
      }, reject)
    });
  }

  performPrint(order: any, printer: any) {   
    
    let content = '';

    // console.log('performPrint', order);

    // switch(order.payment_method) {
    //   case 'invoice':
    //     order.payment_method = 'Rechnung';
    //     break;
    // }
    
    // console.log('at line 61 in performPrint',order)

    content += this.printLeftRight('Bestellnummer', '#'+order.ordernumber);
    content += this.printLeftRight('Bestelldatum', moment(order.orderplacedtime).format('DD.MM.YYYY HH:mm'));
    content += this.printLeftRight('Zahlungsmethode', order.payment || order.payment);
    content += this.printLeftRight('Auftragsart', order.deliverytype);
    // content += this.printLeftRight('Auftragsart', order.deliverytype);
    
    // console.log('at line 67 in performPrint')


    // if(order.shipping_lines && order.shipping_lines[0]) {
    //   let shoppingMethodTranslations = {
    //     'local_pickup': 'Abholung',
    //     'free_shipping': 'Lieferung',
    //   };
    //   content += this.printLeftRight('Liefermethode', shoppingMethodTranslations[order.shipping_lines[0].method_id] || order.shipping_lines[0].method_id);
    // }

    content += '\n';
    content += this.printLeftRight('Lieferdatum', moment(order.deliverydatetime).format('DD.MM.YYYY HH:mm'));
    if(order.note && order.note != ''){
      content += this.printLeftRight('Anmerkung', order.note);
    }
    // console.log('at line 85 in performPrint')

    // if(order.billing) {
      if(!order.isqrcodeorder)
      {
        // console.log('order in if----',order)
      content += '\n\nAdresse:\n';
      content += this.printLeftRight(order.firstName+' '+order.lastName,"");
      content += this.printLeftRight(order.street,"");
      content += this.printLeftRight(order.zipcode+' '+order.city,"");
      content += this.printLeftRight(order.country,"");
      content += this.printLeftRight('Telefon:', order.phone);
      }
      else
      {
        // console.log('order in else----',order)
        content += '\n\nAdresse:\n';
        content += this.printLeftRight(order.qrcodename,"");
        content += this.printLeftRight(order.tablenumber,"");
        content += this.printLeftRight(order.country,"");

      }


      
    // }

    // console.log('print', order);
    this.productList = JSON.parse(order.productList);
    // console.log('this.productList---',JSON.stringify(order.productList));
    
    var total = this.productList.map(function(object) { return object.price*object.amount; }).reduce(function(prevSum, nextNum) { return prevSum + nextNum; });
    
    if(this.productList && this.productList.length) {
      content += '\nProdukte:\n';
      this.productList.forEach((item: any) => {
        // console.log("item.isbundle", item.isbundle)
       
        let name = item.name.replace('&amp;', '&').replace('belegtes', 'bel.');
        // content += this.printLeftRight( (item.amount > 1 ? item.amount + 'x ' : '') + name, (parseFloat(item.price) + parseFloat(item.total_tax)).toFixed(2) + '€');
        // content += this.printLeftRight(item.amount + 'x ' + name, parseFloat(item.price).toFixed(2) + '€');

        let leftString = item.amount + 'x ' + name;
        let leftLine1 = leftString.slice(0, 18);
        let leftLine2 = leftString.slice(18, leftString.length);
        // content += this.printLeftRight(item.amount + 'x ' + name+'abcdef', item.price.toLocaleString('nl-NL',{minimumFractionDigits: 2}) + ' EUR');
        content += this.printLeftRight(leftLine1, item.price.toLocaleString('nl-NL',{minimumFractionDigits: 2}) + ' EUR');
        if(leftLine2.length > 1){
          content += this.printLeftRight(leftLine2, '');
        }
        
        if(item.isbundle){
        let bundleproducts = '';

          // console.log("item.bundleproductlist", item.bundleproductlist)
          let bundleproductlist = JSON.parse(item.bundleproductlist);
          bundleproductlist.forEach((bundleitem: any) => {
            // console.log("bundleitem", bundleitem)
            bundleproducts += '  ' + bundleitem.Quantity + 'x ' + bundleitem.ProductName+ '\n';
          });
        content += this.printLeftRight(bundleproducts  , '');
        }
      });
    }

    content += '\n';
    // content += this.printLeftRight('MwSt', order.total_tax + '€');
    // content += this.printLeftRight('Gesamtpreis', total.toFixed(2) + '€');
    if(order.deliverytype == 'Lieferung'){
      let replaceMinimumValue = order.miniumorderamount.replace(",", ".");
      order.miniumorderamount = replaceMinimumValue;
      // console.log(replaceMinimumValue, total)
      let surCharge = replaceMinimumValue-total;
      surCharge = surCharge > 0 ? surCharge:0;
      content += this.printLeftRight('Lieferzuschlag', surCharge.toLocaleString('nl-NL',{minimumFractionDigits: 2}) + ' EUR');
      total = order.price;
    }

    content += this.printLeftRight('Gesamtpreis', total.toLocaleString('nl-NL',{minimumFractionDigits: 2}) + ' EUR');
    
    if( order.coupondiscount){
      let coupon = order.coupondiscount.replace(",", ".");
      let toPayPrice = (order.price).replace(",", ".") - coupon;
      content += this.printLeftRight('Gutschein', coupon.toLocaleString('nl-NL',{minimumFractionDigits: 2}) + ' EUR');
      content += this.printLeftRight('Zu zahlen', toPayPrice.toLocaleString('nl-NL',{minimumFractionDigits: 2}) + ' EUR');
    }
    
    content += '\n\n\n';
    content = content
      .replace(/€/g, '')
      .replace(/ß/g, 'ss')
      .replace(/ä/g, "ae")
      .replace(/ö/g, "oe")
      .replace(/ü/g, "ue");

    return this.sendToBluetoothPrinter(printer, content);
  }
selectprinter(){
  return new Promise((resolve, reject) => {
  this.searchBluetoothPrinter()
    .then(async (resp: any) => {
      this.bluetoothList = resp;
        let devicesList = [];
        this.bluetoothList.forEach((device: any) => {
          let isbool = false;
          let foundPrinter = window.localStorage.getItem('printer');
          if(foundPrinter == device.id){
            isbool = true;
          }
          devicesList.push({
            type: "radio",
            name: "device",
            value: device.id,
            label: device.name,
            checked: isbool,
          });
        });
        let alert = await this.alertCtrl.create({
          header: "Gerät auswählen",
          inputs: devicesList,
          buttons: [
            {
              text: "Drucken",
              handler: (data: any) => {
                console.log("data", data);
                window.localStorage.setItem('printer', data);
              },
            },
            {
              role: "cancel",
              text: "Abbrechen",
            },
          ],
        });
        alert.present();
    });
  });
}
  print(order: any) {
    return new Promise((resolve, reject) => {
    //this.performPrint(order, null).then(resolve).catch(reject);
    //return null;
      this.searchBluetoothPrinter()
      .then(async (resp: any) => {
        this.bluetoothList = resp;
        // this.bluetoothList.forEach((device: any) => {
        //   alert(device.name);
        // });
       
        let foundPrinterSelect = this.bluetoothList.filter((item: any) => {
            return item.name === "broetchen.taxi Drucker" || item.name === "InnerPrinter" || item.name === "IposPrinter" || item.name === "V2_PRO";
          }),
          //foundPrinter = null; //foundPrinterSelect[0] || null;
          foundPrinter = window.localStorage.getItem('printer');

        if (foundPrinter) {
          this.performPrint(order, foundPrinter).then(resolve).catch(reject);
          // this.performPrint(order, foundPrinter.id).then(resolve).catch(reject);
        } else {
          let devicesList = [];
          this.bluetoothList.forEach((device: any) => {
            devicesList.push({
              type: "radio",
              name: "device",
              value: device.id,
              label: device.name,
            });
          });
  
          let alert = await this.alertCtrl.create({
            header: "Gerät auswählen",
            inputs: devicesList,
            buttons: [
              {
                text: "Drucken",
                handler: (data: any) => {
                  console.log("data", data);
                  window.localStorage.setItem('printer', data);
                  this.performPrint(order, data).then(resolve).catch(reject);
                  // this.printer.print(order, data).then(resolve).catch(reject);
                },
              },
              {
                role: "cancel",
                text: "Abbrechen",
              },
            ],
          });
          alert.present();
        }
      });
    });
  }

  printLeftRight(left: string, right: string) {
    left = left
    .replace(/€/g, '')
    .replace(/ß/g, 'ss')
    .replace(/ä/g, "ae")
    .replace(/ö/g, "oe")
    .replace(/ü/g, "ue");
    right = right
    .replace(/€/g, '')
    .replace(/ß/g, 'ss')
    .replace(/ä/g, "ae")
    .replace(/ö/g, "oe")
    .replace(/ü/g, "ue");

    left = left || '';
    right = right || '';
    let iSpace = (left.length + right.length > 32 ? 63 : 31) - left.length - right.length, space = '';
    if(iSpace > 0) {
      let iCurrent = 0;
      while(iCurrent < iSpace) {
        iCurrent++;
        space += ' ';
      }
    }
    return left + space + right + '\n';
  }

  printLine() {
    return '--------------------------------';
  }

}
