import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Action } from 'rxjs/internal/scheduler/Action';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppcmsService {

  apiUrl: string;  // https://app.broetchen.taxi/api
  _apiUrl: string;
  mainApiUrl:string = environment.apiURL;

  language: String = 'de';

  user = window.localStorage.getItem('appcmsUser') ? JSON.parse(window.localStorage.getItem('appcmsUser')) : null;

  constructor(
    public http: HttpClient
  ) {
    console.log(+'==========='+this.user)
  }

  addFriend(profileId) {
    return new Promise((resolve, reject) => {
      this.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.getUser().uid
        },
        ['add']
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  getApiUrl() {
    return this.apiUrl;
  }

  getLanguage() {
    return this.language;
  }

  getUser() {
    return this.user;
  }

  isFriend(profileId) {
    return new Promise((resolve, reject) => {
      this.loadPluginData(
        'friends',
        {
          partner: profileId,
          userId: this.getUser().uid
        },
        ['isFriend']
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  loadPluginData(plugin: string, data: any = {}, actions = undefined) {
    return new Promise((resolve, reject) => {
      // let action = actions ? typeof actions === 'string' ? actions : actions.join('/') : null, url;
      // if(action) {
      //   url = this.getApiUrl() + '/' + plugin + '/' + Action;
      // } else {
      //   url = this.getApiUrl() + '/' + plugin;
      // }

      let headers = new Headers({ 'Content-Type': 'application/json', 
                                  // 'Authorization': 'Bearer '+this.user.token
                                });
                                console.log(JSON.stringify(headers))
      this.http.post(this.mainApiUrl+plugin, data, { headers: headers as any })
      .subscribe((response: any) => {
        if(response.success !== false && !response.message) {
          resolve(response);
        } else {
          reject(response.message + '== '+plugin || 'Ein unbekannter Fehler ist aufgetreten=='+plugin);
        }
      });
    });
  }

  putPluginData(plugin, data = {}) {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer '+this.user.token,
        })
      };
      // let url = this.getApiUrl() + '/' + plugin;
      this.http.put(this.mainApiUrl+plugin, data, httpOptions)
      .subscribe((response: any) => {
        resolve(response);
      });
    });
  }


  loadCategory(url: string, data: any = {}) {
    return new Promise((resolve, reject) => {
      try {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+this.user.token,
          })
        };
        this.http.get(this.mainApiUrl+url, httpOptions)
        .subscribe(
          (response: any) => {
            if(!response) {
              reject('Ein unbekannter Fehler ist aufgetreten');
            } else
            if(response.success !== false && !response.message) {
              resolve(response);
            } else {
              reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
            }
          },
          (error: any) => {
            let message = error.message;
            if(message.indexOf('Http failure') === 0 || message.indexOf('http failure') === 0) {
              message = 'Wir arbeiten aktuell an unserem Lieferdienst und sind in Kürze wieder da. Bitte versuche es in Kürze erneut.=='+url;
            }
            console.warn('error', error);
            reject(message || 'Ein unbekannter Fehler ist aufgetreten=='+url);
          }
        );
      } catch(e) {
        reject(e.message);
      }
    });
  }


  loadUrl(url: string, data: any = {}) {
    this.user = window.localStorage.getItem('appcmsUser') ? JSON.parse(window.localStorage.getItem('appcmsUser')) : null;
    return new Promise((resolve, reject) => {
      try {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+this.user.token,
          })
        };
        this.http.get(this.mainApiUrl+url, httpOptions)
        .subscribe(
          (response: any) => {
            if(!response) {
              reject('Ein unbekannter Fehler ist aufgetreten');
            } else
            if(response.success !== false && !response.message) {
              resolve(response);
            } else {
              reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
            }
          },
          (error: any) => {
            let message = error.message;
            if(message.indexOf('Http failure') === 0 || message.indexOf('http failure') === 0) {
              message = 'Wir arbeiten aktuell an unserem Lieferdienst und sind in Kürze wieder da. Bitte versuche es in Kürze erneut.=='+url;
            }
            console.warn('error', error);
            reject(message || 'Ein unbekannter Fehler ist aufgetreten=='+url);
          }
        );
      } catch(e) {
        reject(e.message);
      }
    });
  }

  // login(userData) {
  //   return new Promise((resolve, reject) => {
  //     this.postPluginData('user', 'login', { user: userData })
  //     .then((response: any) => {
  //       if(response.success) {
  //         this.setUser(response.user);
  //         resolve(response);
  //       } else {
  //         reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
  //       }
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  postPluginData(plugin, data = {}) {
    console.log(data)
    return new Promise((resolve, reject) => {
      this.http.post(this.mainApiUrl+plugin, data)
      .subscribe((response: any) => {
        resolve(response);
      });
    });
  }

  // register(userData) {
  //   return new Promise((resolve, reject) => {
  //     this.postPluginData('user', 'register', { user: userData })
  //     .then((response: any) => {
  //       if(response.success) {
  //         this.setUser(response.user);
  //         resolve(response);
  //       } else {
  //         reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
  //       }
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  removeFriend(profileId) {
    return new Promise((resolve, reject) => {
      this.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.getUser().uid
        },
        ['remove']
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  // resetApiUrl() {
  //   if(this._apiUrl) {
  //     this.apiUrl = this._apiUrl;
  //   }
  //   window.localStorage.removeItem('apiUrl');
  // }

  // setApiUrl(url: string) {
  //   if(!this._apiUrl) {
  //     this._apiUrl = this.apiUrl;
  //   }
  //   this.apiUrl = url;
  //   window.localStorage.setItem('apiUrl', url);
  // }

  setUser(user: any) {
    this.user = user;
    window.localStorage.setItem('appcmsUser', JSON.stringify(user));

    return this.getUser();
  }

}
