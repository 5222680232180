import { Injectable } from "@angular/core";
import { AppcmsService } from "./appcms.service";

import * as moment from "moment";
import { UserService } from "./user.service";
import { LocalNotifications } from "@ionic-native/local-notifications/ngx";
import { NativeAudio } from "@ionic-native/native-audio/ngx";
import { PrinterService } from "./printer.service";
import { EventsService } from "./events.service";
import { BasketService } from './basket.service';
import { CronjobsService } from "./cronjobs.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OrdersService {

  PrintIntervalId:any;

  deliveryOrders = [];
  
  mapData = new Subject<any>();

  lastOrdersIds: any;

  loadOrdersInterval: any;

  orders: any = window.localStorage.getItem("gildhuisOrders")
    ? JSON.parse(window.localStorage.getItem("gildhuisOrders"))
    : [];

  soundFile: string = "assets/sound.mp3";

  constructor(
    public AppCMS: AppcmsService,
    public basket: BasketService,
    public cronjobs: CronjobsService,
    public events: EventsService,
    public localNotifications: LocalNotifications,
    public nativeAudio: NativeAudio,
    public printer: PrinterService,
    public userService: UserService
  ) {
    moment.locale("de");
  }


  getBranchBytenantId(tenantId) {
    return new Promise((resolve, reject) => {     
        this.AppCMS.loadUrl('Branch/list/'+tenantId, {})
        .then((response: any) => {
          resolve(response);
        })
        .catch(reject);      
    });
  };

  getOrderByBranchId(branchId) {
    return new Promise((resolve, reject) => {     
        this.AppCMS.loadUrl('Order/BranchOrder/'+branchId, {})
        .then((response: any) => {
          resolve(response);
        })
        .catch(reject);      
    });
  };

  printOrder(branchId) {
    return new Promise((resolve, reject) => {  
        this.AppCMS.loadUrl('order/printorder/'+branchId+'/'+moment().format("MM-DD-YYYY HH:mm"), {})
        .then((response: any) => {
          resolve(response);
        })
        .catch(reject);      
    });
  };


  addOrder(order: any) {
    this.orders.push(order);
    return this.setOrders(this.orders);
  }

  checkNewOrders(orders: any[]) {
    console.log('checkNewOrders', orders);

    let newLastOrderIds = [],
      blNewOrders = false,
      orderIds = [];
    this.lastOrdersIds = this.lastOrdersIds || [];

    orders.forEach((order: any) => {
      newLastOrderIds.push(order.id);
    });

    if (this.lastOrdersIds && !this.lastOrdersIds.length && orders) {
      this.lastOrdersIds = newLastOrderIds;
    }

    if (this.orders) {
      this.orders.forEach((order: any) => {
        orderIds.push(order.id);
      });
    }

    if (this.lastOrdersIds.length) {
      let newOrders = [],
        diff = this.diff(orderIds, this.lastOrdersIds);

      diff.forEach((orderId: string) => {
        let orderSelect = orders.filter((_order: any) => {
            return _order.id === parseInt(orderId);
          }),
          order = orderSelect[0] || null;
        if (order) {
          newOrders.push(order);
        }
      });

      blNewOrders =
        JSON.stringify(orderIds) != JSON.stringify(this.lastOrdersIds);

      console.log('blNewOrders', blNewOrders);

      this.lastOrdersIds = newLastOrderIds;
      return newOrders;
    }

    this.lastOrdersIds = newLastOrderIds;
    return blNewOrders;
  }

  create(order: any) {
    return this.AppCMS.loadPluginData(
      "orders",
      {
        order: order,
        user: this.userService.getApiCredentials(),
      },
      ["create"]
    );
  }

  diff(a1: any, a2: any) {
    var a = [],
      diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }

  filterByTime(orders: any, days: number) {
    let now = moment();
    return orders.filter((order: any) => {
      return Math.abs(moment(order.date_created).diff(now, "days")) <= days;
    });
  }

  // get(options: any = {}) {
  //   return new Promise((resolve, reject) => {
  //     this.AppCMS.loadPluginData("woocommerceextend", options, ["orders"])
  //       .then((orders: any) => {
  //         orders = this.filterByTime(orders, 7);
  //         orders.forEach((order: any) => {
  //           order = this.getFullOrder(order);
  //         });

  //         this.orders = orders
  //           .sort((left: any, right: any) => {
  //             return (
  //               parseInt(left.deliveryTime.format("X")) -
  //               parseInt(right.deliveryTime.format("X"))
  //             );
  //           })
  //           .reverse();

  //         resolve(this.orders);
  //       })
  //       .catch(reject);
  //   });
  // }

  // getActive() {
  //   return this.get({
  //     filter: {
  //       status: ["pending", "processing"],
  //     },
  //   });
  // }

  // getAll(filters: any = {}) {
  //   return new Promise((resolve, reject) => {
  //     this.get(filters)
  //     .then((orders: any) => {
  //       resolve(orders);
  //     })
  //     .catch(reject);
  //   });
  // }

  getByTime(time: number, deviceId: number) {
    return this.AppCMS.loadPluginData(
      "devices",
      {
        time: time,
      },
      [deviceId, "orders"]
    );
  }

  getFullOrder(order: any) {
    let localOrderCreatedDate = moment(order.date_created),
      localOrderModifiedDate = moment(order.date_modified),
      localOrderPaidDate = moment(order.date_paid);

    if (order.meta_data) {
      order.meta_data.forEach((metaDataItem: any) => {
        if (
          metaDataItem.key === "Datum (abw. Uhrzeit bitte per Notiz)" ||
          metaDataItem.key === "_orddd_lite_timestamp"
        ) {
          if (metaDataItem.value == parseInt(metaDataItem.value)) {
            order.deliveryTime = moment.unix(metaDataItem.value);
            order.deliveryTime_formatted = order.deliveryTime.format(
              "DD MMMM, YYYY"
            );
          } else {
            let timeByComment,
              format = "DD MMMM, YYYY";
            if (order.customer_note === "sofort") {
              timeByComment = moment(order.date_created).format("HH:mm");
              metaDataItem.value += " " + timeByComment;
              format = "DD MMMM, YYYY HH:mm";
            } else {
              timeByComment = moment(order.customer_note, "HH:mm").format(
                "HH:mm"
              );
              if (timeByComment !== "Invalid date") {
                metaDataItem.value += " " + timeByComment;
                format = "DD MMMM, YYYY HH:mm";
              }
            }
            order.deliveryTime = moment(metaDataItem.value, format);
            order.deliveryTime_formatted = metaDataItem.value;

            if (order.deliveryTime.format("X") == "Invalid date") {
              console.warn("------------------");
              console.log(
                "order.deliveryTime_formatted",
                order.deliveryTime_formatted
              );
              console.log("format", format);
              console.log("order.deliveryTime", order.deliveryTime);
              console.log(
                "order.deliveryTime.format(X)",
                order.deliveryTime.format("X")
              );
              console.warn("------------------");
            }
          }
        }
      });
    }

    order.finished =
      order.status === "completed" ||
      order.status === "cancelled" ||
      order.status === "refunded" ||
      order.status === "trash";

    if (order.deliveryTime) {
      order.today =
        !order.finished &&
        order.deliveryTime.format("DD.MM.YYYY") ==
          moment().format("DD.MM.YYYY");
      order.planned = !order.finished && !order.today;
    } else
    if (!order.finished) {
      order.planned = true;
    }

    order.date_created_formatted =
      order.date_created_formatted ||
      localOrderCreatedDate.format("DD.MM.YYYY HH:mm");
    order.date_modified_formatted =
      order.date_modified_formatted ||
      localOrderModifiedDate.format("DD.MM.YYYY HH:mm");
    order.date_paid_formatted =
      order.date_paid_formatted ||
      localOrderPaidDate.format("DD.MM.YYYY HH:mm");

    if (order.line_items) {
      order.line_items.forEach((item: any) => {
        item.price = (parseFloat(item.total) + parseFloat(item.total_tax)).toFixed(2);
      });
    }

    // set order total to requestMinimumValue if lower
    order.total = order.total > this.basket.getMinimumBasketAmount() ? order.total : this.basket.getMinimumBasketAmount().toFixed(2);
    
    switch(order.payment_method) {
      case 'cod':
        order.payment_method_title = 'Bar';
        break;
      case 'invoice':
        order.payment_method_title = 'Rechnung';
        break;
      case 'paypal':
        order.payment_method_title = 'PayPal';
        break;
    }

    return order;
  }

  // getPending() {
  //   return this.get({
  //     filter: {
  //       status: "pending",
  //     },
  //   });
  // }

  // getProcessing() {
  //   return this.get({
  //     filter: {
  //       status: "processing",
  //     },
  //   });
  // }

  getScheduled() {
    return this.cronjobs.getAll();
  }

  newOrderPush() {
    console.log("newOrderPush");
    try {
      this.localNotifications.schedule({
        title: "Neue Bestellung",
        text: "Es ist eine neue Kundenbestellung eingegangen",
        sound: this.soundFile,
      });
    } catch (e) {
      console.warn("push (e 1)", e);
    }

    try {
      this.nativeAudio
        .play("sound1")
        .then((playResponse: any) => {
          console.log("playResponse", playResponse);
        })
        .catch((e: any) => {
          console.warn("push (e 4)", e);
        });
    } catch (e) {
      console.warn("push (e 2)", e);
    }
  }

  print(order: any) {
    this.printer
      .print(order)
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  setOrders(orders: any) {
    this.orders = orders;
    window.localStorage.setItem("gildhuisOrders", JSON.stringify(this.orders));
  }

  // watch() {
  //   this.loadOrdersInterval = setInterval(() => {
  //     let before = moment().add(1, "month"),
  //       after = moment().subtract(3, "days");

  //     this.getAll({
  //       filter: {
  //         before: before,
  //         after: after,
  //       },
  //     })
  //       .then((orders: any) => {
  //         let newOrders = this.checkNewOrders(orders);
  //         console.log('watch newOrders', newOrders);

  //         if (newOrders && newOrders.length) {
  //           this.events.publish('orders:updated', orders);
  //           newOrders.forEach((order: any, index: number) => {
  //             console.log("-> new order #" + index, order);
  //             this.print(order);
  //           });
  //           this.newOrderPush();
  //         }
  //       })
  //       .catch((error: any) => {
  //         this.events.publish("error", error);
  //       });
  //   }, 30 * 1000);
  // }
}
