import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  tmp: any = {};

  constructor() {

  }

  get(key: string, allowedAge: number = 60) {
    console.log('cache: get', key, allowedAge);
    let data = window.localStorage.getItem(key);
    let timestamp = this.getTimestamp(key);
    let age = this.getAge(key);
    if(data && ((age <= allowedAge) || allowedAge === -1)) {
      return {
        age: age,
        data: data,
        timestamp: timestamp,
      };
    }
  }

  getAge(key: string, unit: any = 'seconds') {
    let timestamp = this.getTimestamp(key);
    if(timestamp) {
      return moment().diff(moment.unix(timestamp), unit);
    }
  }

  getTimestamp(key: string) {
    let value = window.localStorage.getItem(key + '_timestamp');
    return value ? parseInt(value) : null;
  }

  getTmp(key: string) {
    return this.tmp[key];
  }

  remove(key: string) {
    window.localStorage.removeItem(key);
  }

  set(key: string, value: any) {
    console.log('set cache', key);
    window.localStorage.setItem(key, value);
    window.localStorage.setItem(key + '_timestamp', moment().unix() as any);
  }

  setTmp(key: string, value: any) {
    this.tmp[key] = value;
  }

}
