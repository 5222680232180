import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { EventsService } from './events.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CronjobsService {

  intervalTranslations: any = {
    'days': 'täglich',
    'months': 'monatlich',
    'weeks': 'wöchentlich',
  };

  periodTranslations: any = {
    'days': 'Tage',
    'months': 'Monate',
    'weeks': 'Wochen',
  };

  weekdays: any = {
    1: 'Montag',
    2: 'Dienstag',
    3: 'Mittwoch',
    4: 'Donnerstag',
    5: 'Freitag',
    6: 'Samstag',
    7: 'Sonntag',
  };

  constructor(
    public AppCMS: AppcmsService,
    public events: EventsService,
  ) {

  }

  delete(jobId: number) {
    return this.AppCMS.loadPluginData('cronjobs', {}, [jobId, 'delete']);
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('cronjobs')
      .then((cronjobs: any[]) => {
        cronjobs.forEach((cronjob: any) => {
          cronjob = this.getFullCronjob(cronjob);
        });
        resolve(cronjobs);
      })
      .catch(reject);
    });
  }

  getByUid(jobId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('cronjobs', {}, [jobId])
      .then((cronjob: any) => {
        resolve(this.getFullCronjob(cronjob));
      })
      .catch(reject);
    });
  }

  getFullCronjob(order: order) {
    let interval_formatted: string,
        _moment = moment(order.date_scheduled),
        _weekday = _moment.day(),
        weekday = this.weekdays[_weekday],
        time = _moment.format('HH:mm');

    if(order.interval == 1) {
      order.period_formatted = this.intervalTranslations[order.period];
      interval_formatted = `Jeden ${weekday}`;
    } else {
      order.period_formatted = 'Alle ' + order.interval + ' ' + this.periodTranslations[order.period];
    }
    
    order.date_scheduled_formatted = `${interval_formatted} um ${time} Uhr`;

    if(typeof order.user === 'number' && order.requestData && order.requestData.user) {
      order.user = order.requestData.user;
    }
    
    return order;
  }

}
