import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { LoadingController } from '@ionic/angular';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user = window.localStorage.getItem('appcmsUser') ? JSON.parse(window.localStorage.getItem('appcmsUser')) : null;

  constructor(
    public AppCMS: AppcmsService,
    public cache: CacheService,
    public loadingCtrl: LoadingController,
  ) {

  }
  
  addFriend(profileId) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.getUser().uid
        },
        ['add']
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  find(filter: any = {}) {
    return this.AppCMS.loadPluginData('user', {
      filter: filter,
      user: this.getApiCredentials()
    }, ['public']);
  }

  getApiCredentials() {
    let user = this.getUser(true) || {};
    return {
      email: user.email,
      password: user.password,
    };
  }

  getBillingInformation() {
    let user = this.getUser();
    return {
      first_name: user.firstname,
      last_name: user.lastname,
      address_1: user.street + ' ' + user.streetNo,
      address_2: '',
      city: user.city,
      postcode: user.plz,
      country: user.country || 'DE',
      email: user.email,
      phone: user.phone,
    };
  }

  getByClassification(Key, Value, forceReload = false) {
    return this.AppCMS.loadPluginData('classification', {}, ['customers', Key, Value]);
  };
  
  getByUid(uid: any, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      let key = 'user_' + uid, fromCache = this.cache.get(key, 10 * 60);
      if(fromCache && fromCache.data && !blForceRefresh) {
        resolve(JSON.parse(fromCache.data));
      } else {
        this.AppCMS.loadPluginData('user', {}, [uid])
        .then((user: any) => {
          if(user.uid == uid) {
            user = this.getFullUser(user);
            this.cache.set(key, JSON.stringify(user));
            resolve(user);
          } else {
            reject(user);
          }
        })
        .catch(reject);
      }
    });
  };

  getFullUser(user: any) {
    return user;
  }

  getPublic() {
    return this.AppCMS.loadPluginData('user', {}, ['public']);  
  }

  getUser(withPassword: boolean = false) {
    let user = Object.assign({}, this.user);
    if(!withPassword) {
      delete user.password;
    }
    return user;
  }

  isFriend(profileId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          userId: this.getUser().uid
        },
        ['isFriend']
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  login(userData: any) {
    return new Promise((resolve, reject) => {
      this.AppCMS.postPluginData('Staff/login', userData)
      .then((response: any) => {
        if(response.success) {
          response.user.password = userData.password;
          this.setUser(response.user);
          resolve(response);
        } else {
          reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
        }
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  logout() {
    this.setUser({});
  }

  // register(userData: any) {
  //   return new Promise((resolve, reject) => {
  //     this.AppCMS.postPluginData('user', 'register', { user: userData })
  //     .then((response: any) => {
  //       if(response.success) {
  //         this.setUser(response.user)
  //         .then(() => {
  //           resolve(response);
  //         })
  //         .catch(reject);
  //       } else {
  //         reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
  //       }
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  removeFriend(profileId) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.getUser().uid
        },
        ['remove']
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  requestNewPassword(user) {
    return this.AppCMS.loadPluginData('user', {
      user: {
        email: user.email
      }
    }, ['requestNewPassword']);
  }

  setUser(user, blSync = false) {
    return new Promise((resolve, reject) => {
      this.user = user;
      window.localStorage.setItem('appcmsUser', JSON.stringify(user));

      if(blSync) {
        this.update(user)
        .then(() => {
          resolve(this.getUser());
        })
        .catch(reject);
      } else {
        resolve(this.getUser());
      }
    });
  }

  update(user: any) {
    return this.AppCMS.loadPluginData('user', { user: user}, ['update']);  
  }

  validateUser() {
    return new Promise(async (resolve, reject) => {
      let loading = await this.loadingCtrl.create();
      await loading.present();

      let user = this.getUser() || {};
      if (user && user.uid) {
        this.getByUid(user.uid, true)
        .then((user: any) => {
          loading.dismiss();

          if(!user || !user.uid || !user.active) {
            reject('Dieser Account wurde gesperrt.');
            this.logout();
          } else {
            this.setUser(user, false);
            resolve(user);
          }
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
      } else {
        loading.dismiss();
        reject();
      }
    });
  }

}
