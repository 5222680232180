import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  basketStorage: any = window.localStorage.getItem('basket') ? JSON.parse(window.localStorage.getItem('basket')) : [];

  requestMinimumValue: number = 8.50;

  constructor(
    public AppCMS: AppcmsService,
    public userService: UserService,
  ) {

  }

  add(item: any) {
    let storageSimilarItemSearch = this.basketStorage.filter(_item => {
      return item.id === _item.id;
    }), storageSimilarItem = storageSimilarItemSearch[0] || null;
    
    if(storageSimilarItem) {
      storageSimilarItem.amount++;
    } else {
      item.amount = 1;
      this.basketStorage.push(item);
    }

    window.localStorage.setItem('basket', JSON.stringify(this.basketStorage));
  }

  calculateBasketInfo(basketStorage = null) {
    let basketInfo = {
      price_a: 0 as any,
      requestMinimumValue: this.requestMinimumValue as any,
      hasRequestMinimumValue: false as any,
      billing: this.userService.getBillingInformation(),
    };
    basketStorage = basketStorage || this.getBasket();
    basketStorage.forEach((basketItem: any) => {
      if(basketItem.price) {
        basketInfo.price_a += parseInt(basketItem.amount || 1) * parseFloat(basketItem.price);
      }
    });
    if(basketInfo.requestMinimumValue) {
      basketInfo.hasRequestMinimumValue = basketInfo.price_a >= basketInfo.requestMinimumValue;
      basketInfo.requestMinimumValue = basketInfo.requestMinimumValue.toFixed(2);
    }
    basketInfo.price_a = basketInfo.price_a.toFixed(2);
    return basketInfo;
  }

  getBasket() {
    return this.basketStorage;
  }

  getMinimumBasketAmount() {
    return this.requestMinimumValue;
  }

  setBasket(basket: any) {
    this.basketStorage = basket;
    window.localStorage.setItem('basket', JSON.stringify(this.basketStorage));
  }
  
}